import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/index.vue";
import store from "@/store/index";

const router = createRouter({
  history: createWebHistory(), // history 模式
  routes: [
    {
      path: "/",
      component: Index,
      children: [
        {
          path: "/",
          name: "home",
          component: () => import(`../views/home.vue`),
          meta: {
            title: "世象医疗",
          },
        },
        {
          path: "/products",
          name: "products",
          component: () => import(`../views/products.vue`),
          meta: {
            title: "产品描述",
          },
        },
        {
          path: "/aboutUs",
          name: "aboutUs",
          component: () => import(`../views/aboutUs.vue`),
          meta: {
            title: "关于我们",
          },
        },
        {
          path: "/research",
          name: "research",
          component: () => import(`../views/research.vue`),
          meta: {
            title: "研究",
          },
        },
        {
          path: "/cooperate",
          name: "cooperate",
          component: () => import(`../views/cooperate.vue`),
          meta: {
            title: "合作",
          },
        },
        {
          path: "/login",
          name: "login",
          component: () => import(`../views/login.vue`),
          meta: {
            title: "登录",
          },
        },
        {
          path: "/news",
          name: "news",
          component: () => import(`../views/news.vue`),
          meta: {
            title: "新闻",
          },
        },
      ],
    },
    {
      path: "/chart",
      name: "chart",
      component: () => import(`../views/chart.vue`),
      meta: {
        title: "描述诊断",
      },
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import(`../views/chat.vue`),
      meta: {
        title: "在线诊断",
      },
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  linkActiveClass: "link-active",
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
    window.document.title = `${to.meta.title}`;
  }
  if (to.name === "chart" || to.name === "chat") {
    if (!store.state.user)
      next({
        name: "login",
        query: {
          redirectName: to.name,
        },
      });
    next();
  }
  next();
});

router.afterEach((to, from) => {
  console.log(to, from);
  console.log("afterEach");
});

export default router;
