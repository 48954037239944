<template>
  <div class="footer-container">
    <div class="section-container">
      <h3>我们的研究</h3>
      <RouterLink class="pointer link" to="/research">总览</RouterLink>
    </div>
    <div class="section-container">
      <h3>MedWV</h3>
      <a class="pointer">面向所有人</a>
      <a class="pointer">面向公司</a>
      <RouterLink class="pointer link" to="/login">登录</RouterLink>
    </div>
    <div class="section-container">
      <h3>Company</h3>
      <RouterLink class="pointer link" to="/aboutUs">关于我们</RouterLink>
      <RouterLink class="pointer link" to="/news">新闻</RouterLink>
      <a class="pointer">成员</a>
      <a class="pointer">合作伙伴</a>
      <a class="pointer">职业生涯</a>
    </div>
    <div class="section-container">
      <h3>条款和政策</h3>
      <a class="pointer">使用条款</a>
      <a class="pointer">隐私政策</a>
      <a class="pointer">其他政策</a>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.footer-container {
  display: flex;
  height: auto;
  min-height: 200px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
  background-color: whitesmoke;
  width: 100%;
  border-top: 1px solid lightgrey;
}

.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  color: lightskyblue;
}

.link-active {
  text-decoration: underline;
  color: cornflowerblue;
}

.section-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: whitesmoke;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
}
</style>
