<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  window.addEventListener("touchmove", function (e) {
    e.preventDefault();
  });
  window.addEventListener("touchstart", function (e) {
    e.preventDefault();
  });
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

*::-webkit-scrollbar {
  display: none;
}
</style>
