<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="expand-box" @click="doExpand">
    <div class="item-box">
      <div class="item" :style="computedOpacity"></div>
    </div>
    <div class="item-box" style="position: relative;">
      <div
        class="item"
        style="position: absolute;"
        :style="computedRotate('45deg')"
      ></div>
      <div class="item" :style="computedRotate('-45deg')"></div>
    </div>
    <div class="item-box">
      <div class="item" :style="computedOpacity"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expand: Boolean,
  },
  computed: {
    computedOpacity() {
      const { expand } = this;
      return `opacity: ${expand ? "0" : "1"}`;
    },
    computedRotate() {
      return function (deg) {
        const { expand } = this;
        return `transform: ${expand ? `rotate(${deg})` : "rotate(0)"}`;
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 点击触发动画
     */
    doExpand() {
      this.$emit("expand", !this.expand);
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-box {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;

  .item-box {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      width: 80%;
      height: 2px;
      background-color: black;
      border-radius: 1px;
      transition: all 0.35s ease-out;
    }
  }
}
</style>
